import React from 'react';

export default class AutoCompletedText extends React.Component{


    constructor(props){
        super(props)
        this.state = {
            suggestions: [],
            text: '',
            countries : ['Sorry', 'this']
        }
    }

    handleKeyPress(e) {
    if (e.key === 'Enter') {
     var q = document.getElementById("query").value;
     var arrq = q.split(/\s+/);
     q = '';
     for (var ii=0; ii<arrq.length; ++ii)
     q = q + arrq[ii]+ "+";
     //document.getElementById("query").value = q;
      //document.location.href="https://www.google.com/search?q="+q
     var url = "https://www.google.com/search?q="+q;
      window.open(url, '_blank').focus();
     }
    }

    onTextChange = (e) => {
        const value = e.target.value;
        let suggestions = [];
        if(value.length > 0){
            const regex = new RegExp(`^${value}`, 'i');
            suggestions = this.state.countries.sort().filter(v => regex.test(v))
        }

        this.setState(() => ({
            suggestions,
            text: value
        }))
    }

    selectedText(value) {
        this.setState(() => ({
            text: value,
            suggestions: [],
        }))
    }

    renderSuggestions = () => {
        let { suggestions } = this.state;
        if(suggestions.length === 0){
            return null;
        }
        return (
            <ul >
                {
                    suggestions.map((item, index) => (<li  key={index}  onClick={() => this.selectedText(item)} class="sugestlist">{item}</li>))
                }
            </ul>
        );
    }
    
    render() {
        const { text, suggestions } = this.state;
        return(
           
                <div className="btn-group" role="group" aria-label="Basic example">
                                    <form action="" method="GET">
                                        <input type="text" id="query" onChange={this.onTextChange}  placeholder="البحث " value={text} onKeyUp={this.handleKeyPress} className="btn-group1 searchtext"/>
                                        <i onClick={this.handleKeyPress} id="gosearch" className="btnremove"><svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="22">
                        <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                    </svg></i>
                                    </form>
                                {this.renderSuggestions()}
            
            </div>
        );
    }

}