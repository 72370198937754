import React from "react";
import Corp from './Corp';
import NavBR from './Navbar';
import Footer from './Footer';
import Location from './Location';
import Header from './Header';
import Terms from './Terms';
import Privacy from './Privacy';
import Contact from './Contact';
import Publicity from './Publicity';
import About from './About';
import MyGAd2 from './MyGad2';

import { BrowserRouter, Routes, Route} from "react-router-dom";


function Myrout() {
  return (
      <BrowserRouter>
       <div>
        <Routes>
          <Route path="/middle-east" element={<Arabs />} />
          <Route path="/privacy" element={<Privacyf />} />
          <Route path="/contact" element={<Contactf />} />
          <Route path="/terms" element={<Termsf />} />
          <Route path="/publicity" element={<Publicityf />} />
          <Route path="/about" element={<Aboutf /> }/>
          <Route path="/maghreb" element={<Magreb /> } />
          <Route path="/egypt" element={<Egypte /> } />
          <Route path="/gulf-arabic"element={<Golf /> } />
          <Route path="/search" element={<Search /> } />
          <Route path="/" element={<Home /> } />
        </Routes>
      </div>
   </BrowserRouter>

  );
}

function Home() {
  return <div className="App">
    <NavBR />  
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>   
    <MyGAd2 /> 
    <Header/>
    <Corp newsurl="https://news.evnan.com/api/index.php?re=mr&p=" />
   <Location />
      <Footer />
    </div>;
}

function Arabs() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
      <Corp newsurl="https://news.evnan.com/api/index.php?re=me&p=" />
      <Location />
      <Footer />
    </div>;
}

function Egypte() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
      <Corp newsurl="https://news.evnan.com/api/index.php?re=eg&p=" />
      <Location />
      <Footer />
    </div>;
}

function Magreb() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
      <Corp newsurl="https://news.evnan.com/api/index.php?re=mg&p=" />
      <Footer />
      <Location />
    </div>;
}

function Golf() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
      <Corp newsurl="https://news.evnan.com/api/index.php?re=gf&p=" />
      <Location />
      <Footer />
    </div>;
}

function Privacyf() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
    <Privacy />
      <Footer />
    </div>;
}

function Termsf() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
    <Terms />
      <Footer />
    </div>;
}

function Contactf() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
    <Contact />
      <Footer />
    </div>;
}

function Publicityf() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
    <Publicity />
      <Footer />
    </div>;
}

function Aboutf() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
    <About />
      <Footer />
    </div>;
}

function Search() {
  return <div className="App">
    <NavBR />
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <MyGAd2 />
    <Header/>
      <Location />
      <Footer />
    </div>;
}

export default Myrout;