import React from "react";

class ImageLoad extends React.Component {

   constructor(props) {
    super(props);
    this.state = {
      url: "https://www.w3schools.com/",//this.props.url,
      errorimage: "/evnan_logo4x4.png",
    };
    
   
  }

  isLoaded(url){
    
    if(url !==  ""){
    fetch(url, {
    method: 'GET',
    headers: {
      mode: 'cors',
    },
  })
  .then(
        (result) => {  
          return true;
//        console.log('** 2'+result+' ***');        

        },
        (error) => {
          return false;
        }
      );

  }
  }


  render() {
    //document.write("url #### "+this.state.url);
    if(this.isLoaded(this.state.url)){
    return (
<>
  <img src={this.state.url} height='180' alt="" className="mycard-img-top" /> 
</>

      );
  }

  else
    return (
      <div></div>
      );
  }
} export default ImageLoad;
