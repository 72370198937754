import React from "react";
import AdSense from 'react-adsense';

 class MyGAd1 extends React.Component {

   

    componentDidMount() {
     
    }

   render () {
    return(
     <div>
    {/* <AdSense.Google
         client='ca-pub-9101204197222280'
           slot='6304075978' 
             style={{display: 'block'}}
             format='auto'
             responsive='true'
             layout='Display'/>
             */}
        </div>  )
    }
 }

  export default MyGAd1;