import React from "react";
//import logo from './logo.svg';
import './App.css';

//import Main from './Main';
import Myrout from './components/Myrout';

function App() {
   return   <Myrout />;
}

export default App;
